<template>
  <b-row class="match-height">
    <b-col lg="8">
      <EditContest />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EditContest from '@/components/company/contest/EditContest.vue'

export default {
  components: {
    BRow,
    BCol,
    EditContest,
  },
}
</script>
